<template>
    <v-container>
        <v-card class="mb-10">
            <v-card-title class="card-title">Rules Form</v-card-title>
        </v-card>
        <div v-for="i in 5" :key="i">
            <v-row align="center">
                <v-col cols="8">
                    <v-text-field
                        v-if="i === 1"
                        v-model="noOfMatches"
                        :label="`#MatchesRule${i}`"
                        type="number"
                    />
                    <v-text-field
                        v-else-if="i === 2"
                        v-model="noOfMatches2"
                        :label="`#MatchesRule${i}`"
                        type="number"
                    />
                    <v-text-field
                        v-else-if="i === 3"
                        v-model="noOfMatches3"
                        :label="`#MatchesRule${i}`"
                        type="number"
                    />
                    <v-select
                        v-else-if="i === 4"
                        v-model="activePhase"
                        :items="['placements', 'regular_season', 'playoffs']"
                        menu-props="auto"
                        label="Active Phase"
                        hide-details
                        :clearable="!readOnly"
                    />
                    <v-select
                        v-else
                        v-model="activeSeason"
                        :items="seasons"
                        :item-text="(item) => `${item.year} - ${item.type}`"
                        menu-props="auto"
                        label="Active Season"
                        hide-details
                        :clearable="!readOnly"
                        return-object
                    />
                </v-col>
                <v-col cols="4" class="submit-button-wrapper">
                    <v-btn :loading="loading" class="mx-2 white--text" large color="black" @click="editRegulation(i)">
                        Submit
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import configService from '@/services/config'
    import Alert from '@/components/Alert'
    import seasonService from '@/services/season'

    export default {
        name: 'RulesForm',
        components: { Alert },
        data: () => {
            return {
                showAlert: false,
                message: null,
                type: null,
                readOnly: false,
                clearable: true,
                loading: false,
                noOfMatches: 0,
                noOfMatches2: 0,
                noOfMatches3: 0,
                activeSeason: null,
                activePhase: null,
                seasons: [],
                rules: [
                    v => !!v || 'Field is required'
                ]
            }
        },
        created() {
            this.fetchAllSeasons()
            configService.getConfig().then(resp => {
                this.noOfMatches1 = resp.data.noOfMatches
                this.noOfMatches2 = resp.data.noOfMatches2
                this.noOfMatches3 = resp.data.noOfMatches3
                this.activePhase = resp.data.activePhase
                this.activeSeason = resp.data.activeSeason
            })
            // configService.getConfigField('noOfMatches1').then(resp => {
            //     this.noOfMatches1 = resp.data
            // })
            // configService.getConfigField('noOfMatches2').then(resp => {
            //     this.noOfMatches2 = resp.data
            // })
            // configService.getConfigField('noOfMatches3').then(resp => {
            //     this.noOfMatches3 = resp.data
            // })
            // configService.getConfigField('activePhase').then(resp => {
            //     this.activePhase = resp.data
            // })
            // configService.getConfigField('activeSeason').then(resp => {
            //     this.activeSeason = resp.data
            // })
        },
        methods: {
            fetchAllSeasons() {
                seasonService.getSeasons().then(resp => {
                    this.seasons = resp.data.content
                }).catch((error) => {
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            editRegulation(id) {
                let field = ''
                let value = ''
                if (id === 1) {
                    field = 'noOfMatches'
                    value = this.noOfMatches
                } else if (id === 2) {
                    field = 'noOfMatches2'
                    value = this.noOfMatches2
                } else if (id === 3) {
                    field = 'noOfMatches3'
                    value = this.noOfMatches3
                } else if (id === 4) {
                    field = 'activePhase'
                    value = this.activePhase
                } else if (id === 5) {
                    field = 'activeSeason'
                    value = this.activeSeason
                }
                configService.updateConfigField(field, value).then(resp => {
                    this.loading = false
                    this.setAlert(`${field} updated successfully`, 'success')
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            }
        }
    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

</style>
